//
// authentication.scss
//

.auth-body-bg {
  background-color: $card-bg;
}

.authentication-bg {
  background-image: url("../../../images/authentication-bg.jpg");
  // background-image: url("https://images.unsplash.com/photo-1590283603385-17ffb3a7f29f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8Y3J5cHRvfGVufDB8MHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60");
  height: 100vh;
  background-size: cover;
  background-position: center;
  .bg-overlay {
    background-color: #292626;
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.authentication-page-content {
  height: 100vh;
  display: flex;
}

.auth-form-group-custom {
  position: relative;
  .form-control {
    height: 60px;
    padding-top: 28px;
    padding-left: 60px;
  }

  label {
    position: absolute;
    top: 7px;
    left: 60px;
    z-index: 10;
  }

  .auti-custom-input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 19px;
    font-size: 24px;
    color: $primary;
    z-index: 10;
  }
  .toggle-password {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 19px;
    cursor: pointer;
  }
}
.text-danger + .toggle-password {
  display: none;
}

.auth-logo {
  &.logo-light {
    display: $display-none;
  }
  &.logo-dark {
    display: $display-block;
  }
}
