.event-cards {
  .card-img {
    min-height: 250px;
    max-height: 250px;
    object-fit: cover;
  }
  .event-title {
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }
  .logo-host {
    width: 30px;
    border-radius: 100%;
    object-fit: cover;
  }
  .event-badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
  }
  .event-badge-success {
    background-color: rgb(25, 135, 84) !important ;
  }
  .event-badge-warning {
    background-color: rgb(255, 193, 7) !important ;
  }
  .event-badge-danger {
    background-color: rgb(255, 7, 7) !important ;
  }
}
