.datalist {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 100px;
  overflow-y: scroll;
  background-color: white;
  z-index: 99;
  width: 100%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  scrollbar-width: thin !important;
  &.hide {
    display: none;
  }
  &.show {
    display: flex;
  }
  span {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
#ctt {
  margin-right: 10px;
}
// .datalist.hide {
//   display: none;
// }
// .datalist.show {
//   display: flex;
// }
